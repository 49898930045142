import { Fragment, useContext } from "react";
import { Transition } from "@headlessui/react";
import { Switch } from "@headlessui/react";
import { Square3Stack3DIcon } from "@heroicons/react/24/outline";
import moment from "moment";

import ReactSlider from "react-slider";

import DatePicker from "./DatePicker";
import Select from "./Select";
import { UserContext } from "../App";
import { makeRange, makeColorMap } from "../utils.js";
import {
  CROPS_BY_COUNTRY,
  PHENOLOGIES_BY_COUNTRY,
  CROP_COLORS,
  SEQUENTIAL_COLORS,
  SEQUENTIAL_COLORS_10,
  DENSITY_BUCKETS,
  PHENOLOGY_DESCRIPTIONS,
  CLIENTS,
} from "../config/constants.js";
import { getDaysArray } from "../utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function ColorLegend({ colorMapConfig, colorMapMapping, opacity }) {
  const n_steps = 11;
  let colorMapColors = makeColorMap(colorMapMapping, n_steps, opacity);
  let colorMapValues = makeRange(
    colorMapConfig.min,
    colorMapConfig.max,
    n_steps,
  );
  return (
    <div className="text-gray-200 block px-4 py-4 text-xs text-bold text-left">
      <strong>{colorMapConfig.legendTitle}</strong>
      <div className="flex flex-row justify-between">
        {colorMapValues.map((d, i) => {
          const color = colorMapColors[i];
          return (
            <div key={i}>
              <span
                className="legend-key"
                style={{
                  backgroundColor: color,
                }}
              ></span>
            </div>
          );
        })}
      </div>
      <div className="flex flex-row justify-between">
        {colorMapValues.map((d, i) => {
          if (i % 5 === 0) {
            return <span>{d.toFixed(1)}</span>;
          } else {
            return <></>;
          }
        })}
      </div>
    </div>
  );
}
export default function MyPopover({
  dataLoaded,
  fieldOpacity,
  setFieldOpacity,
  satelliteOpacity,
  setSatelliteOpacity,
  currentTab,
  setCurrentTab,
  zoomLevel,
  toggleStats,
  selectorOpen,
  modifierOpen,
  showS1Tiles,
  setShowS1Tiles,
  showS2Tiles,
  setShowS2Tiles,
  showS2Minitiles,
  setShowS2Minitiles,
  showActiveRegions,
  setShowActiveRegions,
  showLandcoverField,
  setShowLandcoverField,
  showLandcoverTree,
  setShowLandcoverTree,
  clickLayer,
  clickSelector,
  list,
  satelliteColorMap,
}) {
  const {
    groups,
    navigation,
    regions,
    regionOptions,
    country,
    statsDate,
    setStatsDate,
    crop,
    satelliteLayers,
    mapRef,
    datesOptions,
    date,
    setDate,
    showFieldDelineation,
    setShowFieldDelineation,
    statsOpen,
    activeSatelliteLayer,
    setRegionByLevel,
    debugMode,
  } = useContext(UserContext);
  const showPhenologicalStage = crop !== "All";

  const changeOpacity = (value, layerName, layerType) => {
    const layers = mapRef.getStyle().layers;
    for (const layer in layers) {
      const id = layers[layer]["id"];
      if (mapRef.getLayoutProperty(id, "visibility") === "none") {
        continue;
      }
      if (layerName.includes(id)) {
        const property =
          layerType === "vector" ? "fill-opacity" : "raster-opacity";
        if (layerType === "vector") {
          setFieldOpacity(value / 100);
        } else {
          setSatelliteOpacity(value / 100);
        }
        mapRef.setPaintProperty(id, property, value / 100);
      }
    }
  };

  const getOptions = (currentNode) => {
    let out_dict = { All: "All" };
    currentNode.children.forEach((child) => {
      out_dict[child.id] = child.name;
    });
    return out_dict;
  };

  // Recursive function to generate Select components
  const generateSelects = (currentNode, level = 0) => {
    if (
      !currentNode ||
      currentNode.children.map((child) => child.children).length === 0
    ) {
      return null;
    }
    function findChild(c) {
      return c.id === regions[level];
    }
    const ind = currentNode.children.findIndex(findChild);
    const label = `Region ${level + 1}`;

    return (
      <div key={level}>
        <strong className="text-white">{`${label}: `}</strong>
        <Select
          key={label}
          label={""}
          options={getOptions(currentNode)}
          value={regions[level]}
          onChange={(value) => setRegionByLevel(value, level)}
        />
        {regions[level] !== "All" &&
          generateSelects(currentNode.children[ind], level + 1)}
      </div>
    );
  };

  const changeCrop = (value) => {
    navigation[1].onChange(value);
    if (currentTab === "Crop type" && value !== "All") {
      setCurrentTab("Phenological stage");
    } else if (currentTab === "Phenological stage" && value === "All") {
      setCurrentTab("Crop type");
    }
  };

  return (
    <div className="flex flex-col md:flex-row">
      {/*Left*/}
      <div className="top-0 mt-4 mr-4 flex flex-col">
        <div className="border border-gray-600 shadow-lg inline-block ml-4 z-20 w-56 origin-top-left rounded-md bg-gray-900 bg-opacity-80 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="text-left inline-flex w-full justify-center gap-x-1.5 px-4 py-4 text-sm font-medium text-gray-900 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-xs flex flex-col">
            <div>
              <DatePicker
                options={getDaysArray(
                  "2023-01-01",
                  moment().format("YYYY-MM-DD"),
                )}
                value={statsDate}
                onChange={setStatsDate}
                dark={false}
              />
            </div>
            <div>{generateSelects(regionOptions)}</div>
            <div className="mt-3">
              <strong className="text-white">Crop: </strong>
              <Select
                key={"Crop"}
                label={""}
                options={CROPS_BY_COUNTRY[country]}
                value={navigation[1].value}
                onChange={(value) => {
                  changeCrop(value);
                  setShowLandcoverField(false);
                  setShowLandcoverTree(false);
                }}
              />
            </div>
          </div>
          {/*Divider*/}
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-500" />
            </div>
          </div>
          {debugMode && groups?.includes("admin") && (
            <>
              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-4 mx-4"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-100"
                    passive
                  >
                    S1 Tiles
                  </Switch.Label>
                </span>
                <Switch
                  checked={showS1Tiles}
                  onChange={() => setShowS1Tiles(!showS1Tiles)}
                  className={classNames(
                    showS1Tiles ? "bg-cyan-800" : "bg-gray-500",
                    "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showS1Tiles ? "translate-x-3" : "translate-x-0",
                      "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                  />
                </Switch>
              </Switch.Group>
              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-4 mx-4"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-100"
                    passive
                  >
                    S2 Tiles
                  </Switch.Label>
                </span>
                <Switch
                  checked={showS2Tiles}
                  onChange={() => setShowS2Tiles(!showS2Tiles)}
                  className={classNames(
                    showS2Tiles ? "bg-cyan-800" : "bg-gray-500",
                    "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showS2Tiles ? "translate-x-3" : "translate-x-0",
                      "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                  />
                </Switch>
              </Switch.Group>

              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-4 mx-4"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-100"
                    passive
                  >
                    S2 Minitiles
                  </Switch.Label>
                </span>
                <Switch
                  checked={showS2Minitiles}
                  onChange={() => setShowS2Minitiles(!showS2Minitiles)}
                  className={classNames(
                    showS2Minitiles ? "bg-cyan-800" : "bg-gray-500",
                    "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showS2Minitiles ? "translate-x-3" : "translate-x-0",
                      "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                  />
                </Switch>
              </Switch.Group>

              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-4 mx-4"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-100"
                    passive
                  >
                    Active Regions
                  </Switch.Label>
                </span>
                <Switch
                  checked={showActiveRegions}
                  onChange={() => setShowActiveRegions(!showActiveRegions)}
                  className={classNames(
                    showActiveRegions ? "bg-cyan-800" : "bg-gray-500",
                    "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showActiveRegions ? "translate-x-3" : "translate-x-0",
                      "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                  />
                </Switch>
              </Switch.Group>

              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-4 mx-4"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-100"
                    passive
                  >
                    Show field/no-field
                  </Switch.Label>
                </span>
                <Switch
                  checked={showLandcoverField}
                  onChange={() => {
                    setShowLandcoverField(!showLandcoverField);
                    setShowLandcoverTree(false);
                    changeCrop("All");
                  }}
                  className={classNames(
                    showLandcoverField ? "bg-cyan-800" : "bg-gray-500",
                    "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showLandcoverField ? "translate-x-3" : "translate-x-0",
                      "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                  />
                </Switch>
              </Switch.Group>

              <Switch.Group
                as="div"
                className="flex items-center justify-between mt-4 mx-4"
              >
                <span className="flex flex-grow flex-col">
                  <Switch.Label
                    as="span"
                    className="text-sm font-medium leading-6 text-gray-100"
                    passive
                  >
                    Show tree/no-tree
                  </Switch.Label>
                </span>
                <Switch
                  checked={showLandcoverTree}
                  onChange={() => {
                    setShowLandcoverTree(!showLandcoverTree);
                    setShowLandcoverField(false);
                    changeCrop("All");
                  }}
                  className={classNames(
                    showLandcoverTree ? "bg-cyan-800" : "bg-gray-500",
                    "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      showLandcoverTree ? "translate-x-3" : "translate-x-0",
                      "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                    )}
                  />
                </Switch>
              </Switch.Group>
            </>
          )}
          <Switch.Group
            as="div"
            className="flex items-center justify-between mt-4 mx-4"
          >
            <span className="flex flex-grow flex-col">
              <Switch.Label
                as="span"
                className="text-sm font-medium leading-6 text-gray-100"
                passive
              >
                {crop === "All" ? "Crop detection" : "Phenological stage"}
              </Switch.Label>
            </span>
            <Switch
              checked={showFieldDelineation}
              onChange={() => setShowFieldDelineation(!showFieldDelineation)}
              className={classNames(
                showFieldDelineation ? "bg-cyan-800" : "bg-gray-500",
                "relative inline-flex h-4 w-7 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  showFieldDelineation ? "translate-x-3" : "translate-x-0",
                  "-translate-y-0 pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                )}
              />
            </Switch>
          </Switch.Group>

          {showFieldDelineation &&
            dataLoaded &&
            zoomLevel > 9 &&
            (showPhenologicalStage ? (
              <div className="z-10 w-56 origin-top-left rounded-md ring-opacity-5 focus:outline-none">
                <span className="text-gray-200 block px-4 py-4 text-xs text-bold">
                  {CLIENTS[country]["phenologyModelCrops"].includes(crop) ? (
                    PHENOLOGIES_BY_COUNTRY[country]?.map((pheno, i) => {
                      const color = SEQUENTIAL_COLORS[i];
                      return (
                        <div key={i}>
                          <span
                            className="legend-key"
                            style={{
                              backgroundColor: color,
                              opacity: 1,
                            }}
                          ></span>
                          <span>{PHENOLOGY_DESCRIPTIONS[crop]?.[pheno]}</span>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      No phenological stage model for this crop available
                    </div>
                  )}
                </span>
              </div>
            ) : (
              <>
                <span className="text-gray-200 block px-4 py-4 text-xs text-bold">
                  {CROPS_BY_COUNTRY[country]?.slice(1).map((crop, i) => {
                    const color = CROP_COLORS[i];
                    return (
                      <div key={i}>
                        <span
                          className="legend-key"
                          style={{
                            backgroundColor: color,
                            opacity: 1,
                          }}
                        ></span>
                        <span>{crop == "grain" ? "cereals" : crop}</span>
                      </div>
                    );
                  })}
                </span>
              </>
            ))}
          {showFieldDelineation && dataLoaded && zoomLevel > 9 && (
            <>
              <div className="px-4 py-4 text-center">
                <div className="mx-auto text-xs text-gray-200 flex flex-row space-x-4 items-center">
                  <p>Opacity</p>
                  <ReactSlider
                    className="customSlider w-36"
                    trackClassName="customSlider-track"
                    thumbClassName="customSlider-thumb"
                    markClassName="customSlider-mark"
                    marks={20}
                    min={0}
                    max={100}
                    defaultValue={50}
                    onChange={(value) =>
                      changeOpacity(
                        value,
                        showLandcoverField
                          ? "parcels-fill-landcover-field"
                          : showLandcoverTree
                            ? "parcels-fill-landcover-tree"
                            : showPhenologicalStage
                              ? "parcels-fill-pheno"
                              : "parcels-fill-crop",
                        "vector",
                      )
                    }
                  />
                </div>
              </div>
            </>
          )}

          {showFieldDelineation &&
            dataLoaded &&
            zoomLevel < 9 &&
            statsOpen &&
            currentTab === "Land use" && (
              <>
                <span className="text-gray-200 block px-4 py-4 text-xs text-bold">
                  <strong>Color legend for plot density</strong>
                  <div className="flex flex-row justify-between">
                    {DENSITY_BUCKETS.map((d, i) => {
                      const color = SEQUENTIAL_COLORS_10[i];
                      return (
                        <div key={i}>
                          <span
                            className="legend-key"
                            style={{
                              backgroundColor: color,
                              opacity: fieldOpacity,
                            }}
                          ></span>
                        </div>
                      );
                    })}
                  </div>
                </span>
                <div className="px-4 py-4 text-center">
                  <div className="mx-auto text-xs text-gray-200 flex flex-row space-x-4 items-center">
                    <p>Opacity</p>
                    <ReactSlider
                      className="customSlider w-36"
                      trackClassName="customSlider-track"
                      thumbClassName="customSlider-thumb"
                      markClassName="customSlider-mark"
                      marks={20}
                      min={0}
                      max={100}
                      defaultValue={50}
                      onChange={(value) =>
                        changeOpacity(value, "country-boundary-fill", "vector")
                      }
                    />
                  </div>
                </div>
              </>
            )}
          <div className="z-20">
            <div className="mt-2 p-2 flex items-center justify-center w-full">
              <button
                type="button"
                onClick={() => toggleStats()}
                className="flex-grow bg-cyan-800 bg-opacity-80 p-2 rounded-md text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              >
                {statsOpen ? "Hide statistics" : "Show statistics"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*Right*/}
      <div className="top-0 mt-4 mr-4 flex flex-col">
        <div className="border border-gray-600 shadow-lg inline-block ml-4 z-20 w-56 origin-top-left rounded-md bg-gray-900 bg-opacity-80 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <button
            onClick={() => clickSelector()}
            className={`
              ${selectorOpen ? "" : "text-opacity-90"}
              "flex inline-flex w-full justify-between gap-x-1.5 rounded-md bg-gray-900 bg-opacity-80 p-2 pl-3 text-sm font-medium text-white hover:bg-opacity-30 fo s:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {activeSatelliteLayer === null
              ? "Choose satellite layer"
              : activeSatelliteLayer.texts}
            <Square3Stack3DIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
          <Transition
            as={Fragment}
            show={selectorOpen}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <div className="z-10 py-4 w-56 origin-top-left shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="flex flex-col space-y-2">
                {activeSatelliteLayer !== null && (
                  <button
                    key={activeSatelliteLayer.texts}
                    onClick={() => clickLayer(null)}
                    className="text-left flex-1 rounded-lg p-2 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-20 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                  >
                    <div className="h-4 pl-2">
                      <span className="text-gray-200 block text-sm">
                        No layer
                      </span>
                    </div>
                  </button>
                )}
                {satelliteLayers.map((layer) => {
                  return (
                    layer.id === activeSatelliteLayer?.id || (
                      <button
                        key={layer.id}
                        onClick={() => clickLayer(layer)}
                        className="text-left flex-1 rounded-lg p-2 transition duration-150 ease-in-out hover:bg-black hover:bg-opacity-20 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                      >
                        <div className="h-4 pl-2">
                          <span className="text-gray-200 block text-sm">
                            {layer.texts}
                          </span>
                        </div>
                      </button>
                    )
                  );
                })}
              </div>
            </div>
          </Transition>

          {activeSatelliteLayer !== null && modifierOpen && (
            <div className="z-10 text-center py-4 w-56 origin-top-left rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="px-2">
                <DatePicker
                  options={datesOptions}
                  value={date}
                  onChange={setDate}
                />
                {activeSatelliteLayer.colorMap?.legendTitle &&
                  satelliteColorMap && (
                    <ColorLegend
                      colorMapConfig={activeSatelliteLayer.colorMap}
                      colorMapMapping={satelliteColorMap}
                      opacity={satelliteOpacity}
                    />
                  )}
                <div className="px-4 mt-4 mx-auto text-xs text-gray-200 flex flex-row space-x-4 items-center">
                  <p>Opacity</p>
                  <ReactSlider
                    className="customSlider w-36"
                    trackClassName="customSlider-track"
                    thumbClassName="customSlider-thumb"
                    markClassName="customSlider-mark"
                    marks={20}
                    min={0}
                    max={100}
                    defaultValue={100}
                    onChange={(value) =>
                      changeOpacity(
                        value,
                        activeSatelliteLayer.id + "-tilesets-layer",
                        "raster",
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
