// Adapted from https://github.com/alphagov/govuk-frontend/blob/6805620cf93b5b92dea8e97a4af1cbf329697fee/packages/govuk-frontend/src/govuk/components/exit-this-page/exit-this-page.mjs
// Press SHIFT three times to toggle debug mode

export default class DebugHandler {
  /** @private */
  keypressCounter = 0;

  /** @private */
  lastKeyWasModified = false;

  /** @private */
  timeoutTime = 5000; // milliseconds

  // Store the timeout events so that we can clear them to avoid user keypresses overlapping
  // setTimeout returns an id that we can use to clear it with clearTimeout,
  // hence the 'Id' suffix

  /**
   * @private
   * @type {number | null}
   */
  keypressTimeoutId = null;

  /**
   * @private
   * @type {number | null}
   */
  timeoutMessageId = null;

  /**
   * @param {Element | null} $root - HTML element that wraps the Exit This Page button
   * @param {ExitThisPageConfig} [config] - Exit This Page config
   */
  constructor(setDebugMode) {
    this.setDebugMode = setDebugMode;
    document.addEventListener("keyup", this.handleKeypress.bind(this), true);
  }

  toggleDebug() {
    this.setDebugMode((debugMode) => !debugMode);
  }

  /**
   * Logic for the 'quick escape' keyboard sequence functionality (pressing the
   * Shift key three times without interruption, within a time limit).
   *
   * @private
   * @param {KeyboardEvent} event - keyup event
   */
  handleKeypress(event) {
    // Detect if the 'Shift' key has been pressed. We want to only do things if it
    // was pressed by itself and not in a combination with another key—so we keep
    // track of whether the preceding keyup had shiftKey: true on it, and if it
    // did, we ignore the next Shift keyup event.
    //
    // This works because using Shift as a modifier key (e.g. pressing Shift + A)
    // will fire TWO keyup events, one for A (with e.shiftKey: true) and the other
    // for Shift (with e.shiftKey: false).
    if (event.key === "Shift" && !this.lastKeyWasModified) {
      this.keypressCounter += 1;

      // Clear the timeout for the keypress timeout message clearing itself
      if (this.timeoutMessageId) {
        window.clearTimeout(this.timeoutMessageId);
        this.timeoutMessageId = null;
      }

      if (this.keypressCounter >= 3) {
        this.keypressCounter = 0;

        if (this.keypressTimeoutId) {
          window.clearTimeout(this.keypressTimeoutId);
          this.keypressTimeoutId = null;
        }

        this.toggleDebug();
      }

      this.setKeypressTimer();
    } else if (this.keypressTimeoutId) {
      // If the user pressed any key other than 'Shift', after having pressed
      // 'Shift' and activating the timer, stop and reset the timer.
      this.resetKeypressTimer();
    }

    // Keep track of whether the Shift modifier key was held during this keypress
    this.lastKeyWasModified = event.shiftKey;
  }

  /**
   * Starts the 'quick escape' keyboard sequence timer.
   *
   * This can be invoked several times. We want this to be possible so that the
   * timer is restarted each time the shortcut key is pressed (e.g. the user has
   * up to n seconds between each keypress, rather than n seconds to invoke the
   * entire sequence.)
   *
   * @private
   */
  setKeypressTimer() {
    // Clear any existing timeout. This is so only one timer is running even if
    // there are multiple keypresses in quick succession.
    if (this.keypressTimeoutId) {
      window.clearTimeout(this.keypressTimeoutId);
    }

    // Set a fresh timeout
    this.keypressTimeoutId = window.setTimeout(
      this.resetKeypressTimer.bind(this),
      this.timeoutTime,
    );
  }

  /**
   * Stops and resets the 'quick escape' keyboard sequence timer.
   *
   * @private
   */
  resetKeypressTimer() {
    if (this.keypressTimeoutId) {
      window.clearTimeout(this.keypressTimeoutId);
      this.keypressTimeoutId = null;
    }

    this.keypressCounter = 0;

    this.timeoutMessageId = window.setTimeout(() => null, this.timeoutTime);
  }
}
